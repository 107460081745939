import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Button from './Buttons';
import Toast from './Toast';
const EmailForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            from_name: name,
            from_email: email,
            message: message,
        };

        emailjs
            .send('service_ngqj3hr', 'template_548h2dm', formData, 'wbky4GAeciMCv6Q06')
            .then(
                (result) => {
                    setName('');
                    setEmail('');
                    setMessage('');
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 7000);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className="contact-container">
            <h3>NAPISZ DO NAS</h3>
            <form ref={form} className="contact-form" onSubmit={handleSubmit}>
                <div className="input_container">
                    <label htmlFor="name">Imię:</label>
                    <input
                        className="inputfield"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="input_container">
                    <label htmlFor="email">Email:</label>
                    <input
                        className="inputfield"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input_container">
                    <label htmlFor="message">Wiadomość:</label>
                    <textarea
                        className="inputfield"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                <Button id="email_button" type={"submit"}>
                    Wyślij wiadomość →
                </Button>
            </form>
            {showToast && <Toast message="Wiadomość została wysłana!" duration={7000} />}
        </div>
    );
};

export default EmailForm;