import React from 'react';
import LandingPage from './Pages/LandingPage';
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import AdminDashboard from './Pages/AdminDashboard';
import Oferta from "./Pages/Oferty";
import Aktualnosci from './Pages/Aktualnosci';
import { Routes, Route, Navigate } from "react-router-dom";
const App = () => {

    return (

        <Routes>
            <Route path="/home" exact element={<LandingPage />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register />} />
            <Route path="/admin" exact element={<AdminDashboard />} />
            <Route path="/aktualnosci" exact element={<Aktualnosci />} />
            <Route path="/oferta" exact element={<Oferta />} />
            <Route path="/" element={<Navigate to="/home" />} />
        </Routes>

    );
};

export default App;
