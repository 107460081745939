import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { backendURL } from '../backendURL';

const About = () => {
  const [descriptions, setDescriptions] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchDescriptions = async () => {

      try {
        const response = await axios.get(backendURL + '/api/description');
        setDescriptions(response.data.descriptions);

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchDescriptions();
  }, [descriptions]);


  useEffect(() => {
    if (descriptions.length > 0) {
      const description = descriptions.find((item) => item.category === "Strona główna");
      if (description && description.description)
        setDescription(description);

    }
  }, [descriptions]);


  const htmlContent = description && description.description; // Retrieve HTML content from MongoDB

  const formattedText = htmlContent ? htmlContent.replace(/\n/g, "<br />") : '';
  const sanitizedHTML = DOMPurify.sanitize(formattedText);

  return (
    <div className="about-section">
      <div className="about__header">
        <h1> Anwood Świat Drewna – skład wyrobów z drewna , głównie iglastego (sosna, świerk , modrzew itp ).</h1>
      </div>
      <div className="text-section" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
    </div>
  );
};

export default About;