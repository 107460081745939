import React, { useState, useEffect } from 'react';
import { backendURL } from '../backendURL';
const PhotoSlide = () => {
  const [images, setImages] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState(0);

  useEffect(() => {
    fetch(backendURL + '/api/images')
      .then((response) => response.json())
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  /*
  useEffect(() => {
    if (filteredImages.length > 0) {
      const timer = setInterval(() => {
        setCurrentPhoto((prevPhoto) =>
          prevPhoto === filteredImages.length - 1 ? 0 : prevPhoto + 1
        );
      }, 5900);

      return () => {
        clearInterval(timer);
      };
    }
  }, [images]);
  */

  const filteredImages = images.filter(
    (item) => item.itemCategory === 'Strona główna'
  );

  const goToNextPhoto = () => {
    setCurrentPhoto((prevPhoto) =>
      prevPhoto === filteredImages.length - 1 ? 0 : prevPhoto + 1
    );
  };

  const goToPreviousPhoto = () => {
    setCurrentPhoto((prevPhoto) =>
      prevPhoto === 0 ? filteredImages.length - 1 : prevPhoto - 1
    );
  };

  return (
    <div className="photo-gallery">
      {filteredImages.length > 0 && (
        <div
          className="image-transition"
          style={{
            backgroundImage: `url(${filteredImages[currentPhoto].imageURL})`,
          }}
        />
      )}

      <button className='slide_button' id="prev_button" onClick={goToPreviousPhoto}><box-icon type='solid' name='left-arrow'></box-icon></button>
      <button className='slide_button' id="next_button" onClick={goToNextPhoto}><box-icon type='solid' name='right-arrow'></box-icon></button>

    </div>
  );
};

export default PhotoSlide;
