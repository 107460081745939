import React, { useState } from 'react';

import LOGO from '../logo.png';
import Button from './Buttons';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'; // import the ScrollLink component
import ContactSection from './Contact';

const HamburgerMenu = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        // Close the hamburger menu when a link is clicked
        setIsOpen(false);
    };


    return (
        <div className="hamburger-menu">
            <button className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
                <box-icon name='menu'> Menu</box-icon>
                Menu
            </button>
            <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
                <div className="navar__buttons__hamburger">

                    {location.pathname === '/home' ? (
                        <ScrollLink to="home" smooth={true} duration={500} offset={-400} className='navbar-button' onClick={handleLinkClick}>
                            Strona główna
                        </ScrollLink>
                    ) :
                        <Link className='navbar-button__hamburger' to="/home" onClick={handleLinkClick}>
                            <Button isNavbarButton={true}>Strona główna</Button>
                        </Link>}
                    {location.pathname === '/oferta' ? (
                        <ScrollLink to="oferta" smooth={true} duration={500} offset={-400} className='navbar-button' onClick={handleLinkClick}>
                            Oferta
                        </ScrollLink>
                    ) :
                        <Link className='navbar-button__hamburger' to="/oferta" onClick={handleLinkClick}>
                            <Button isNavbarButton={true}>Oferta</Button>
                        </Link>}

                    {location.pathname === '/aktualnosci' ? (
                        <ScrollLink to="/aktualnosci" smooth={true} duration={500} offset={-100} className='navbar-button' onClick={handleLinkClick}>
                            Aktualności
                        </ScrollLink>
                    ) :
                        <Link className='navbar-button__hamburger' to="/aktualnosci" onClick={handleLinkClick}>
                            <Button isNavbarButton={true}>Aktualności</Button>
                        </Link>}






                    <ScrollLink to="contact" smooth={true} duration={500} offset={-100} className='navbar-button' onClick={handleLinkClick}>
                        Kontakt
                    </ScrollLink>
                    <Link className='navbar-button__hamburger' to="https://www.olx.pl/oferty/uzytkownik/27vGX/" onClick={handleLinkClick} >
                        <Button isNavbarButton={true}>Oferta sprzedaży</Button>
                    </Link>
                </div>
            </ul>
        </div>
    );
};

export default HamburgerMenu;