
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import axios from 'axios';

import ImageGallery from './ImageGallery';
import Button from '../Components/Buttons';
import CategoryTag from '../Components/CategoryTag';
import { backendURL } from '../backendURL';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCPW4m1GoccTAeYUbBgL82Qg6JRpqwD3h4",
    authDomain: "anwood-58084.firebaseapp.com",
    projectId: "anwood-58084",
    storageBucket: "anwood-58084.appspot.com",
    messagingSenderId: "398371715148",
    appId: "1:398371715148:web:62304e8b4e5ec3b5261ce9",
    measurementId: "G-SL2ZS92TQP"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const UploadForm = () => {

    const [buttons, setButtons] = useState([
        { id: 1, name: 'Deski i listwy suszone komorowo' },
        { id: 2, name: 'Płyty OSB, budowlane' },
        { id: 3, name: 'Drewno konstrukcyjne C24' },
        { id: 4, name: 'Łaty dachowe' },
        { id: 5, name: 'Tarasy drewniane i kompozytowe, pergole' },
        { id: 6, name: 'Tarcica ogólnobudowlana' },
        { id: 7, name: 'Elewacje drewniane, podbitki, lamele' },
    ]);





    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [descriptions, setDescriptions] = useState([]);
    const [description, setDescription] = useState('');

    const [selectedButton, setSelectedButton] = useState("Deski i listwy suszone komorowo")

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFile(null);
            setFileName('');
        }
    };
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        localStorage.setItem('selectedButton', buttonName);
    };


    useEffect(() => {
        const storedButton = localStorage.getItem('selectedButton');

        if (storedButton) {
            setSelectedButton(storedButton);
        }

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            const storageRef = storage.ref();
            const fileRef = storageRef.child(fileName);

            await fileRef.put(file);

            const downloadUrl = await fileRef.getDownloadURL();
            const fullPath = fileRef.fullPath;



            const payload = {
                fileUrl: downloadUrl,
                buttonName: selectedButton,
                deleteUrl: fullPath,
            };

            axios
                .post(backendURL + '/api/upload', payload)
                .then((response) => {

                    // Perform any further actions with the response
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                });
        }
        else {
            alert("Nie wybrano pliku")
        }
    };



    const handleDescriptionUpdate = async (e) => {
        e.preventDefault();

        if ((description && selectedButton) || selectedButton === "Alert") {

            const payload = {
                description: description,
                category: selectedButton,
            };

            axios
                .post(backendURL + '/api/description', payload)
                .then((response) => {

                    // Perform any further actions with the response
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Error updating description:', error);
                });
        }
        else {
            alert("Nie wybrano pliku")
        }
    };

    useEffect(() => {
        const fetchDescriptions = async () => {

            try {
                const response = await axios.get(backendURL + '/api/description');
                setDescriptions(response.data.descriptions);

            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchDescriptions();
    }, [selectedButton]);

    useEffect(() => {
        if (descriptions.length > 0) {
            const description = descriptions.find((item) => item.category === selectedButton);
            if (description && description.description)
                setDescription(description.description);
        }
    }, [descriptions, selectedButton]);

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className='admin_dashboard'>

                    <div className='admin_dashboard_buttons'>
                        <div>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Strona główna')}
                                className={selectedButton === 'Strona główna' ? 'selected' : ''}>

                                Strona głowna
                            </CategoryTag>

                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Adres')}
                                className={selectedButton === 'Adres' ? 'selected' : ''}
                            >
                                Kontakt - Adres
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Email')}
                                className={selectedButton === 'Email' ? 'selected' : ''}
                            >
                                Kontakt - Email
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Telefon')}
                                className={selectedButton === 'Telefon' ? 'selected' : ''}
                            >
                                Kontakt - Telefon
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('GodzinyPracy')}
                                className={selectedButton === 'GodzinyPracy' ? 'selected' : ''}
                            >
                                Kontakt - Godziny Pracy
                            </CategoryTag>

                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Alert')}
                                className={selectedButton === 'Alert' ? 'selected' : ''}
                            >
                                Czerwony baner
                            </CategoryTag>

                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Ogłoszenia')}
                                className={selectedButton === 'Ogłoszenia' ? 'selected' : ''}
                            >
                                Ogłoszenia (Link do strony)
                            </CategoryTag>
                        </div>



                        <div>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Deski i listwy suszone komorowo')}
                                className={selectedButton === 'Deski i listwy suszone komorowo' ? 'selected' : ''}
                            >
                                Deski i listwy suszone komorowo
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Płyty OSB, budowlane')}
                                className={selectedButton === 'Płyty OSB, budowlane' ? 'selected' : ''}
                            >
                                Płyty OSB, budowlane
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Drewno konstrukcyjne C24')}
                                className={selectedButton === 'Drewno konstrukcyjne C24' ? 'selected' : ''}
                            >
                                Drewno konstrukcyjne C24
                            </CategoryTag>
                        </div>
                        <div>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Elewacje drewniane, podbitki, lamele')}
                                className={selectedButton === 'Elewacje drewniane, podbitki, lamele' ? 'selected' : ''}
                            >
                                Elewacje drewniane, podbitki, lamele
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Tarasy drewniane i kompozytowe, pergole')}
                                className={selectedButton === 'Tarasy drewniane i kompozytowe, pergole' ? 'selected' : ''}
                            >
                                Tarasy drewniane i kompozytowe, pergole
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Tarcica ogólnobudowlana')}
                                className={selectedButton === 'Tarcica ogólnobudowlana' ? 'selected' : ''}
                            >
                                Tarcica ogólnobudowlana
                            </CategoryTag>
                            <CategoryTag
                                type="button"
                                onClick={() => handleButtonClick('Łaty dachowe')}
                                className={selectedButton === 'Łaty dachowe' ? 'selected' : ''}
                            >
                                Łaty dachowe
                            </CategoryTag>



                        </div>

                    </div>
                    <input id="file_button" type="file" onChange={handleFileChange} />
                    <Button type="submit">WGRAJ WYBRANE ZDJĘCIE DO STRONY {selectedButton}</Button>
                </div >
            </form >
            <form onSubmit={handleDescriptionUpdate}>
                <div className='input_container'>
                    <label htmlFor="Description">Opis do strony {selectedButton}:</label>
                    <textarea
                        className='admin_text_area'
                        id="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required={!selectedButton === "Alert"}
                    ></textarea>
                </div>
                <Button type="submit">ZAAKTUALIZUJ OPIS DO STRONY {selectedButton}</Button>
            </form>
            <ImageGallery admin currentlySeleccted={selectedButton}></ImageGallery>
        </React.Fragment>
    );
};


export default UploadForm;
