import React, { useState, useEffect } from 'react';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import EmailForm from './EmailForm';
import axios from 'axios';
import { backendURL } from '../backendURL';
const ContactSection = (props) => {
  const [descriptions, setDescriptions] = useState([]);
  const [description, setDescription] = useState('');

  const [adres, setAdres] = useState('');
  const [email, setEmail] = useState('');
  const [telefon, setTelefon] = useState('');
  const [godziny, setGodziny] = useState('');

  useEffect(() => {
    const fetchDescriptions = async () => {

      try {
        const response = await axios.get(backendURL + '/api/description');
        setDescriptions(response.data.descriptions);

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchDescriptions();
  }, [props.currentlySeleccted]);

  useEffect(() => {
    if (descriptions.length > 0) {
      const godziny = descriptions.find((item) => item.category === "GodzinyPracy");
      if (godziny && godziny.description)
        setGodziny(godziny);

      const adres = descriptions.find((item) => item.category === "Adres");
      if (adres && adres.description)
        setAdres(adres);

      const email = descriptions.find((item) => item.category === "Email");
      if (email && email.description)
        setEmail(email);

      const telefon = descriptions.find((item) => item.category === "Telefon");
      if (telefon && telefon.description)
        setTelefon(telefon);

    }
  }, [descriptions, props.currentlySeleccted]);
  if (!props.navbar) {
    return (

      <div id="contact" className="contact-section">
        <div className='contact-card'>
          <h3>KONTAKT</h3>
          <div className='contact-info'>
            <div className='contact-item'> <box-icon name='world'></box-icon>ANWOOD</div>
            <br />
            <div className='contact-item'><box-icon type="solid" name="map"></box-icon>{adres.description}</div>
            <br />
            <div className='contact-item'> <box-icon name='envelope' ></box-icon>{email.description}</div>
            <br />
            <div className='contact-item'><box-icon type='solid' name='phone-call'></box-icon><a href="tel:534008202">{telefon.description}</a></div>
            <br />
            <div className='contact-item'><box-icon name='time-five'></box-icon>{godziny.description}</div>
          </div>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918.2851203442016!2d19.504811400974724!3d51.70989199158468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a339c4ae0d699%3A0xa456e2e75642066f!2sAnwood%20Sk%C5%82ad%20Drewna%20-%20%C5%9Awiat%20Drewna!5e0!3m2!1spl!2spl!4v1687373917291!5m2!1spl!2spl"
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <EmailForm />
      </div>
    );
  }
  else {
    return (
      <div className='contact-info__nav'>
        <div className='contact-item__nav'><box-icon type="solid" name="map"></box-icon>{adres.description}</div>
        <div className='contact-item__nav'> <box-icon name='envelope' ></box-icon>{email.description}</div>
        <div className='contact-item__nav'><box-icon type='solid' name='phone-call'></box-icon><a href="tel:534008202">{telefon.description}</a></div>
        <div className='contact-item__nav'><box-icon name='time-five'></box-icon>{godziny.description}</div>
      </div>);
  }

};

export default ContactSection;
