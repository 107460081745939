
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

import CategoryTag from './CategoryTag';
import ImageGallery from '../Pages/ImageGallery';

import { Link as ScrollLink } from 'react-scroll'; // import the ScrollLink component
const OfertaSection = () => {
    const [selectedButton, setSelectedButton] = useState("Deski i listwy suszone komorowo")


    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        localStorage.setItem('selectedButton', buttonName);
    };


    return (
        <React.Fragment>

            <div id="oferta" className='category_button_group'>
                <h1>Wybierz kategorię</h1>
                <div className='category_button_group__row'>

                    <ScrollLink to="description" smooth={true} duration={500} offset={-520} >
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Deski i listwy suszone komorowo')}
                            isNavbarButton={false}
                            className={selectedButton === 'Deski i listwy suszone komorowo' ? 'selected' : ''}
                        >
                            Deski i listwy suszone komorowo
                        </CategoryTag>
                    </ScrollLink>

                    <ScrollLink to="description" smooth={true} duration={500} offset={-520} >
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Płyty OSB, budowlane')}
                            isNavbarButton={false}
                            className={selectedButton === 'Płyty OSB, budowlane' ? 'selected' : ''}
                        >
                            Płyty OSB, budowlane
                        </CategoryTag>
                    </ScrollLink>

                    <ScrollLink to="description" smooth={true} duration={500} offset={-520} >
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Drewno konstrukcyjne C24')}
                            isNavbarButton={false}
                            className={selectedButton === 'Drewno konstrukcyjne C24' ? 'selected' : ''}
                        >
                            Drewno konstrukcyjne C24
                        </CategoryTag>
                    </ScrollLink>

                    <ScrollLink to="description" smooth={true} duration={500} offset={-520}>
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Łaty dachowe')}
                            isNavbarButton={false}
                            className={selectedButton === 'Łaty dachowe' ? 'selected' : ''}
                        >
                            Łaty dachowe
                        </CategoryTag>
                    </ScrollLink>
                </div>
                <div className='category_button_group__row'>
                    <ScrollLink to="description" smooth={true} duration={500} offset={-520} >
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Tarasy drewniane i kompozytowe, pergole')}
                            isNavbarButton={false}
                            className={selectedButton === 'Tarasy drewniane i kompozytowe, pergole' ? 'selected' : ''}
                        >
                            Tarasy drewniane i kompozytowe, pergole
                        </CategoryTag>
                    </ScrollLink>

                    <ScrollLink to="description" smooth={true} duration={500} offset={-520}>
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Tarcica ogólnobudowlana')}
                            isNavbarButton={false}
                            className={selectedButton === 'Tarcica ogólnobudowlana' ? 'selected' : ''}
                        >
                            Tarcica ogólnobudowlana
                        </CategoryTag>
                    </ScrollLink>
                    <ScrollLink to="description" smooth={true} duration={500} offset={-520}>
                        <CategoryTag
                            tag
                            type="button"
                            onClick={() => handleButtonClick('Elewacje drewniane, podbitki, lamele')}
                            isNavbarButton={false}
                            className={selectedButton === 'Elewacje drewniane, podbitki, lamele' ? 'selected' : ''}
                        >
                            Elewacje drewniane, podbitki, lamele
                        </CategoryTag>
                    </ScrollLink>

                </div>
            </div >

            <ImageGallery currentlySeleccted={selectedButton}></ImageGallery>
        </React.Fragment>

    );
};


export default OfertaSection;
