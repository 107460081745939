import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { backendURL } from '../backendURL';
const firebaseConfig = {
    apiKey: "AIzaSyCPW4m1GoccTAeYUbBgL82Qg6JRpqwD3h4",
    authDomain: "anwood-58084.firebaseapp.com",
    projectId: "anwood-58084",
    storageBucket: "anwood-58084.appspot.com",
    messagingSenderId: "398371715148",
    appId: "1:398371715148:web:62304e8b4e5ec3b5261ce9",
    measurementId: "G-SL2ZS92TQP"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

const ImageGallery = (props) => {
    const [images, setImages] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [description, setDescription] = useState('');


    useEffect(() => {
        fetch(backendURL + '/api/images')
            .then((response) => response.json()) // Use response.text() instead of response.json()
            .then((data) => {

                setImages(data);
                // Further actions with the data
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }, []);


    const filteredImages = images.filter(item => item.itemCategory === props.currentlySeleccted);

    useEffect(() => {
        const fetchDescriptions = async () => {

            try {
                const response = await axios.get(backendURL + '/api/description');
                setDescriptions(response.data.descriptions);

            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchDescriptions();
    }, [props.currentlySeleccted]);

    useEffect(() => {
        if (descriptions.length > 0) {
            const description = descriptions.find((item) => item.category === props.currentlySeleccted);
            if (description && description.description)
                setDescription(description);

        }
    }, [descriptions, props.currentlySeleccted]);




    const handleDelete = (imageId, URL) => {
        axios
            .delete(backendURL + `/api/images/${imageId}`, {
                data: { URL: URL }, // Pass URL as data in the request body
            })
            .then((response) => {

                // Update the images state to remove the deleted image
                setImages((prevImages) =>
                    prevImages.filter((image) => image._id !== imageId)
                );
            })
            .catch((error) => {
                console.error('Error deleting image:', error);
            });
    };

    const htmlContent = description && description.description; // Retrieve HTML content from MongoDB

    const formattedText = htmlContent ? htmlContent.replace(/\n/g, "<br />") : '';
    const sanitizedHTML = DOMPurify.sanitize(formattedText);

    return (
        <React.Fragment>
            <div className={props.admin ? 'ImageGallery_admin' : 'ImageGallery'}>
                {!props.admin && (
                    <div className='category_title'>
                        {description.category}
                    </div>
                )}
                {!props.admin && (
                    <div className='category_description' id="description" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                )}
                {filteredImages.map((item, index) => (
                    <div key={index}>
                        {props.admin && (
                            <React.Fragment>
                                <button id="delete_button" onClick={() => handleDelete(item._id, item.deleteUrl)}>USUN ZDJECIE</button>
                            </React.Fragment>
                        )}
                        {/* <p>{item.itemCategory}</p> */}
                        <img className={props.admin ? "img_embeded_admin" : "img_embeded"} src={item.imageURL} />
                    </div>
                ))}
            </div>
        </React.Fragment>

    );
};

export default ImageGallery;