import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../backendURL';
const Warning = (props) => {
    const [descriptions, setDescriptions] = useState([]);
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchDescriptions = async () => {

            try {
                const response = await axios.get(backendURL + '/api/description');
                setDescriptions(response.data.descriptions);

            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchDescriptions();
    }, [props.currentlySeleccted]);

    useEffect(() => {
        if (descriptions.length > 0) {
            const description = descriptions.find((item) => item.category === "Alert");
            if (description && description.description)
                setDescription(description);

        }
    }, [descriptions, props.currentlySeleccted]);
    if (description != "" && description) {
        return (
            <div className='contact-info__nav--warning'>
                <div className='contact-item__nav'>{description.description}</div>
            </div>);
    }

};

export default Warning;
