
import LOGO from '../logo.png';
import Button from './Buttons';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'; // import the ScrollLink component
import ContactSection from './Contact';
import HamburgerMenu from './HamburgerMenu';
import Warning from './Warning';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { backendURL } from '../backendURL';

const Navbar = () => {
  const location = useLocation();
  const [descriptions, setDescriptions] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchDescriptions = async () => {

      try {
        const response = await axios.get(backendURL + '/api/description');
        setDescriptions(response.data.descriptions);

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchDescriptions();
  }, []);

  useEffect(() => {
    if (descriptions.length > 0) {
      const description = descriptions.find((item) => item.category === "Ogłoszenia");
      if (description && description.description)
        setDescription(description);

    }
  }, [descriptions]);

  return (
    <nav className='navbar'>
      <div className='navbar_top'>
        <img src={LOGO} alt="Logo" className="logo" />
        <HamburgerMenu></HamburgerMenu>
        <div className="navar__buttons">

          {location.pathname === '/home' ? (
            <ScrollLink to="home" smooth={true} duration={500} offset={-400} className='navbar-button'>
              Strona główna
            </ScrollLink>
          ) :
            <Link className='navbar-button' to="/home">
              <Button isNavbarButton={true}>Strona główna</Button>
            </Link>}
          {location.pathname === '/oferta' ? (
            <ScrollLink to="oferta" smooth={true} duration={500} offset={-400} className='navbar-button'>
              Oferta
            </ScrollLink>
          ) :
            <Link className='navbar-button' to="/oferta">
              <Button isNavbarButton={true}>Oferta</Button>
            </Link>}



          {location.pathname === '/aktualnosci' ? (
            <ScrollLink to="/aktualnosci" smooth={true} duration={500} offset={-100} className='navbar-button' >
              Aktualności
            </ScrollLink>
          ) :
            <Link className='navbar-button' to="/aktualnosci" >
              <Button isNavbarButton={true}>Aktualności</Button>
            </Link>}



          <ScrollLink to="contact" smooth={true} duration={500} offset={-100} className='navbar-button'>
            Kontakt
          </ScrollLink>
          <a className='navbar-button' href={description.description} target="_blank" rel="noopener noreferrer">Oferty sprzedaży</a>
        </div>
      </div>
      <div className="subnavbar">
        <ContactSection navbar></ContactSection>
        <Warning />
      </div>
    </nav>
  );
};

export default Navbar;
