import React from 'react';
import Navbar from '../Components/Navbar';
import About from '../Components/About';
import PhotoGallery from '../Components/PhotoSlide';
import ContactSection from '../Components/Contact';

const Aktualnosci = () => {

    return (
        <div className='app'>
            <Navbar></Navbar>
            <div className='anwood_app__container'>
                <div className='aktualnosci_container'>
                    <iframe className='fb_feed'
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDrewnoElewacyjne&tabs=timeline&width=500&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width="500"
                        height="1000"
                        style={{
                            border: 'none',
                            overflow: 'hidden',
                            scrolling: 'no',
                        }}
                        frameBorder="0"
                        allowFullScreen={true}

                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        data-width="500"
                        data-show-posts="true"
                    ></iframe>
                    <a id="/aktualnosci" className="fb_bug" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/DrewnoElewacyjne">Jeżeli posty nie ładują się, kliknij tutaj!</a>
                    <iframe className='fb_feed_mobile' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDrewnoElewacyjne&tabs=timeline&width=350&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="350" height="450" style={{
                        border: 'none',
                        overflow: 'hidden',
                        scrolling: 'no',
                    }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    {/* <div class="fb-page" data-href="https://www.facebook.com/DrewnoElewacyjne" data-show-posts="true" data-width="" data-height="" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/DrewnoElewacyjne" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/DrewnoElewacyjne">Anwood</a></blockquote></div> */}
                </div>
                <ContactSection></ContactSection>
            </div>
        </div>
    );


};

export default Aktualnosci;
