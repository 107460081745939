import React, { useState, useEffect } from 'react';


const Toast = ({ message, duration }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
            }, duration);
        }
    }, [message, duration]);

    if (!visible) {
        return null;
    }

    return (
        <div className="toast">
            {message}
        </div>
    );
};

export default Toast;
