import React from 'react';
import Navbar from '../Components/Navbar';
import About from '../Components/About';
import PhotoGallery from '../Components/PhotoSlide';
import ContactSection from '../Components/Contact';
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import AutoPlayVideo from '../Components/AutoPlayVideo';

const LandingPage = () => {
    return (
        <div className='app'>
            <Navbar></Navbar>
            <div id='home' className='anwood_app__container'>
                <AutoPlayVideo></AutoPlayVideo>
                <div className='andwood-hero'>ANWOOD <br />SKŁAD DREWNA</div>
                <About></About>
                <div className="about__header">
                    <h1> GALERIA ZDJĘĆ</h1>
                </div>
                <PhotoGallery />
                <div className="about__header">
                    <h1> ODWIEDŹ NAS!
                        <br /> PUNKT SPRZEDAŻY | MAGAZYN
                        <br />93-610 ŁÓDŹ UL. KOLUMNY 107</h1>
                </div>
                <Element name="contact">
                    <ContactSection></ContactSection>
                </Element>

            </div>
        </div>
    );
};

export default LandingPage;