import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import UploadForm from './UploadForm';
import { backendURL } from '../backendURL';
const AdminDashboard = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    async function test() {
        try {
            const req = await fetch(backendURL + '/api/quote', {
                headers: {
                    'x-access-token': localStorage.getItem('token'),
                },
            });

            const data = await req.json();
            setEmail(data.user);
        } catch (error) {
            // Handle the error
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const user = jwtDecode(token);

                if (user.exp * 1000 < Date.now()) {
                    // Token is expired
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    // Token is valid, make the API request
                    test();
                }
            } catch (error) {
                // Invalid token
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            // No token found
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div>
            <h3>Panel administratora</h3>
            <UploadForm />
        </div>
    );
};

export default AdminDashboard;
