import React from 'react';

const Button = ({ isNavbarButton, onClick, type, children, id }) => {
    const buttonClass = isNavbarButton ? 'navbar-button' : 'button';

    return (
        <button id={id} type={type} className={buttonClass} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;