import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'boxicons';
import App from './App';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
const root = ReactDOM.createRoot(document.getElementById('root'));
// Check if the URL contains the hash routing (#)
const hasHashRouting = window.location.hash;

if (!hasHashRouting) {
  const pathname = window.location.pathname;
  if (pathname === '/home') {
    // Redirect from /home to /#/home
    window.location.href = window.location.origin + '/#/home';
  } else {
    // Redirect to the default route /#/home
    window.location.href = window.location.origin + '/#/home';
  }
}
root.render(
  <HashRouter>
    <ScrollToTop />
    <App />
  </HashRouter>
);
