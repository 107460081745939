import React, { useState } from 'react';
import { backendURL } from '../backendURL';
const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      console.log('Please fill in both email and password fields');
      return;
    }

    // Create a registration object with email and password
    const registerData = {
      email: email,
      password: password
    };

    try {
      // Make a POST request to the backend API endpoint
      const response = await fetch(backendURL + '/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registerData)
      });

      if (response.ok) {
        // Register successful
        console.log('Register successful!');
        setEmail('');
        setPassword('');
        const data = await response.json();
        console.log(data);

      } else {
        // Registration failed
        console.log('Register failed!');
        const errorData = await response.json();
        console.log('Error:', errorData.error);
      }
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <button type="submit">Register</button>
    </form>
  );
};

export default Register;
