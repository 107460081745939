import React from 'react';
import Navbar from '../Components/Navbar';
import About from '../Components/About';
import PhotoGallery from '../Components/PhotoSlide';
import ContactSection from '../Components/Contact';
import UploadForm from './UploadForm';
import ImageMapping from '../Components/OfertaSection';

const Oferta = () => {
    return (
        <div className='app'>
            <Navbar></Navbar>

            <div className='anwood_app__container'>
                <ImageMapping></ImageMapping>
                <ContactSection></ContactSection>
            </div>
        </div>
    );
};

export default Oferta;