import React from 'react';

const CategoryTag = ({ isNavbarButton, onClick, type, children, id, className }) => {
    return (
        <button id={id} type={type} className={`button_tag ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default CategoryTag;